import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import { Link } from 'gatsby'
import { Typography, Box } from '@material-ui/core'
import { isTablet } from 'react-device-detect'
import chirpyestLogo from '../assets/images/chirpyest-logo.svg'
import safariAllow from '../assets/images/safari-allow-ext.png'
import arrowBack from '../assets/images/arrow-back-header.svg'
import extensionIcon from '../assets/images/extension-icon.svg'
import appIcon from '../assets/images/app-icon.svg'
import toggleIcon from '../assets/images/ios-toggle-icon.svg'
import Button from '../components/button'
import { styles } from '../pagesStyles/welcome'

declare const window: any

const ExtensionWelcomePage = () => {
  const { search } = useLocation()
  const { isExtensionEnabled } = parse(search)
  const [step, setStep] = useState(isExtensionEnabled ? 1 : 0)
  const classes = styles()

  useEffect(() => {
    if (step === 1) {
      window.localStorage.setItem('appInstalled', 'true')
    }
  }, [step])

  return (
    <div className={classes.container}>
      <div>
        {step !== 0 && (
          <img
            src={arrowBack}
            alt="Go Back"
            className={classes.goBack}
            onClick={() => setStep(0)}
          />
        )}
        <img
          src={chirpyestLogo}
          alt="Chirpyest Logo"
          className={classes.logo}
        />
      </div>
      <div className={classes.content}>
        {step === 0 ? (
          <>
            <Typography variant="body1" className={classes.title}>
              how to enable the chirpyest extension for safari?
            </Typography>
            <video
              playsInline
              loop
              autoPlay
              muted
              preload="none"
              className={classes.media}
            >
              <source
                src={
                  isTablet
                    ? 'https://dl.dropboxusercontent.com/sh/kgzyvpvjy64dqxb/AAC6oOyM27l_JorujXlld59ia/chirpyest_4_how%20to%20enable%20the%20chirpyest%20extension%20for%20safari_iPad_v1.mp4?dl=0'
                    : 'https://dl.dropboxusercontent.com/sh/kgzyvpvjy64dqxb/AABOZJPwveIeW3Dp9SF6d8-Da/chirpyest_4_how%20to%20enable%20the%20chirpyest%20extension%20for%20safari_v2.mp4?dl=0'
                }
                type="video/mp4"
              />
            </video>
            <ol className={classes.list}>
              <li className={classes.listItem}>
                tap the
                <b>
                  <span style={{ fontSize: '12px' }}> A</span>A&nbsp;
                </b>
                in the address bar
              </li>
              <li className={classes.listItem}>
                <div className={classes.textWithIcons}>
                  tap manage extensions
                  <img src={extensionIcon} className={classes.inlineIcon} />
                </div>
              </li>
              <li className={classes.listItem}>
                <div className={classes.textWithIcons}>
                  turn on chirpyest
                  <img src={appIcon} className={classes.inlineIcon} />
                  <img src={toggleIcon} className={classes.inlineIcon} />
                </div>
              </li>
            </ol>
          </>
        ) : (
          <>
            <Typography variant="body1" className={classes.title}>
              now we need your permission to give you cash back
            </Typography>
            <Box mt={1.25}>
              <Typography variant="body1" className={classes.text}>
                we don’t sell or share your data, it’s in our{' '}
                <Link to="/company#privacy" className={classes.link}>
                  privacy policy
                </Link>
              </Typography>
            </Box>
            <img
              src={safariAllow}
              className={classes.media}
              alt="IOS Allow access"
            />
            <ol className={classes.list}>
              <li className={classes.listItem}>
                tap the{' '}
                <b>
                  <span style={{ fontSize: '12px' }}> A</span>A&nbsp;
                </b>
                in the address bar
              </li>
              <li className={classes.listItem}>
                tap the chirpyest extension{' '}
                <img
                  src={chirpyestLogo}
                  alt="Chirpyest Logo"
                  className={classes.extensionLogo}
                />
              </li>
              <li className={classes.listItem}>
                tap <span style={{ fontWeight: 600 }}>‘Always Allow’</span> then
                <span style={{ fontWeight: 600 }}>
                  ‘Always Allow on Every Website’
                </span>
              </li>
            </ol>
            {/* <Button
              onClick={() => {}} // TODO: add review button function
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label="review"
            /> */}
          </>
        )}
      </div>
    </div>
  )
}

export default ExtensionWelcomePage
